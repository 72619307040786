























import { defineComponent, PropType, ref } from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import ExtractFormInput from '@/components/organisms/h/item/formInput/ExtractFormInput.vue'
import ExtractFormSelect from '@/components/organisms/h/item/formSelect/ExtractFormSelect.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import { UserExtractCondition } from '@/types/entities'
import { userExtractIsAdministratorOptions } from '@/utils/constant'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    HLine,
    ExtractFormInput,
    ExtractFormSelect,
  },
  props: {},
  setup(_, context) {
    // 発注方式のセレクト部品の初期値とOption
    const options = userExtractIsAdministratorOptions
    const defaultSelected = null
    const type = 'text'
    let extractLoginId = ''
    let extractIsAdministrator = ''

    /**
     * 抽出条件変更イベント
     */
    const changeExtractCondition = () => {
      const base: UserExtractCondition = {
        isAdministrator: extractIsAdministrator || '',
        // TODO: ログインID
        loginId: extractLoginId || '',
      }
      let cond = { ...base }
      context.emit('change-extract-condition', cond)
    }
    const changeExtractConditionOfLoginId = (param: any) => {
      console.log('changeExtractConditionOfLoginId', param)
      extractLoginId = param
      changeExtractCondition()
    }
    const changeExtractConditionOfIsAdministrator = (param: string) => {
      console.log('changeExtractConditionOfIsAdministrator', param)
      extractIsAdministrator = param
      changeExtractCondition()
    }

    return {
      type,
      defaultSelected,
      options,
      changeExtractConditionOfLoginId,
      changeExtractConditionOfIsAdministrator,
    }
  },
})
