





















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/common/user/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import { UserOutputCondition, UserExtractCondition } from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
} from '@/types/generated/graphql'
import { OUTPUT_COND_DELETE, EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  delOutput: [],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  mounted() {
    if (this.$store.state.app.userInfo !== null) {
      if (this.$store.state.app.userInfo.isAdministrator !== null) {
        this.isAdministrator = this.$store.state.app.userInfo.isAdministrator
      }
    }
  },
  data() {
    return {
      resourceType: ResourceType.UiPUser,
      templateFile: TemplateFile.UiPUser_01,
      extractCondition: {
        loginId: '',
        isAdministrator: '',
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      isAdministrator: false,
    }
  },
  methods: {
    changeExtractCondition(cond: UserExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
    },
    changeOutputCondition(cond: UserOutputCondition) {
      this.outputCondition = cond
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPUsers.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.user,
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const where = this._createWhere()
      const orderBy = this._createOrderByStatement()
      const rowQuery = `
        query c {
          vUiPUsers(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                passwordResetRequestFlg
                inactiveFlg
                loginId
                displayName
                email
                isAdministrator
                configuredFlg
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'loginId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // ログインIDの抽出条件
        {
          field: ['loginId'],
          op: WhereOp.Startswith,
          value: [''],
        },
        // システム管理者権限の抽出条件
        {
          field: ['isAdministrator'],
          op: WhereOp.Eq,
          value: [''],
        },
        // 出力条件
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(where, 'loginId', [this.extractCondition.loginId])
      setWhereValue(where, 'isAdministrator', [
        this.extractCondition.isAdministrator,
      ])
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])

      console.log({ where })
      return where
    },
  },
})
