














import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import { string } from 'prop-types'

import FormInput from '@/components/organisms/c/FormInput.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import { formSelectExtractCondition } from '@/types/entities'

export default defineComponent({
  components: {
    FormInput,
    ExtractConditionRowLayout,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    defaultText: {
      default: null,
    },
    placeholder: {
      default: null,
    },
    type: {
      default: 'text',
    },
  },
  /*********************************
   * 抽出条件変更時の処理
   *********************************/
  methods: {
    changeText: function (param: string) {
      console.log('changeText', param)
      this.$emit('changeText', param)
    },
  },
})
