














export default {
  data() {
    return {
      text: this.defaultText,
    }
  },
  props: ['placeholder', 'defaultText', 'type'],
  methods: {
    changeText(v: string) {
      console.log(v)
      this.$emit('changeText', v)
    },
  },
}
